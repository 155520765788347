$color-primary: #6b7abb;
$color-light-primary: #cfdcc6;
$color-secondary: #ffffff;
$color-grey: #c4c4c4;
$color-info: #e8f1fb;
$cta-background-color: hsl(0, 0%, 100%);
$cta-text-color: #1e1717;
$input-error-color: rgba(255, 255, 255, 0.6);
$input-info-color: rgba(255, 255, 255);
$form-placeholder-opacity: 1;
$default-font: 'ConcertOne';

$footer-text-color: #1e1717;
$insert-coin-color: rgba(0, 0, 0, 0.2);
//SLOT MACHINE
$color-machine-levier: #6b7abb;
$color-machine-head: #1e2c68;
$color-machine-border: #1e2c68;
//WEB RESPONSIVE
$xs-height: 330px;
$s-height: 565px;
$m-height: 745px;

$mobile-landscape: 639px;
$smartphone-height: 641px;
$iphone-x-height: 823px;
$smartphone-width: 414px;
$tablet-portrait: 768px;
$tablet-landscape: 1024px;

//WHEEL MACHINE
$color-wheel-outer-radious: #1e2c68;

:export {
  primary: $color-primary;
  lightPrimary: 'black';
  outerRadious: $color-wheel-outer-radious;
}

// Breakpoint
$mq-breakpoints: (
  mobile-sm: 350px,
  mobile: $mobile-landscape,
  tablet-portrait: $tablet-portrait,
  tablet: $tablet-landscape,
  desktop-sm: 1366px,
  desktop: 1440px,
  desktop-large: 1600px,
  desktop-xlarge: 1681px,
  mobileLandscape: $mobile-landscape,
  xs-height: $xs-height,
  s-height: $s-height,
  m-height: $m-height,
);
